<template>
  <div class="sidebar-wrapper">
    <transition name="slide">
      <div v-if="isShowing" class="sidebar shadow bg-white text-dark">
        <header class="sidebar-header">
          <button type="button" class="close text-dark" @click="hide">
          <span class="material-icons-outlined">
            close
          </span>
          </button>
          <strong id="sidebar-title">{{ title }}</strong>
        </header>
        <div class="sidebar-body">
          <slot/>
        </div>
        <footer class="sidebar-footer">
          <slot name="footer"></slot>
        </footer>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'SidebarComponent',
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      isShowing: false,
    };
  },
  methods: {
    toggle() {
      this.isShowing = !this.isShowing;
    },
    show() {
      this.isShowing = true;
    },
    hide() {
      this.isShowing = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: visible;
  z-index: 1035;

  .sidebar {
    position: fixed;
    top: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 525px;
    max-width: 100%;
    max-height: 100%;
    margin: 0;
    outline: 0;
    left: auto;
    right: 0;
    overflow: auto;

    .sidebar-header {
      font-size: 1.5rem;
      padding: .5rem 40px;
      align-items: center;
    }

    .sidebar-body {
      padding-left: 40px;
      padding-right: 40px;
      height: 100%;
    }
    .sidebar-footer {
      padding-top: 12px;
      padding-bottom: 12px;
      margin-left: 40px;
      margin-right: 40px;
      border-top: 1px solid #E4E7EC;
    }
  }
}

.slide-enter-active {
  transition: all .3s ease;
}

.slide-leave-active {
  transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-enter, .slide-leave-to {
  transform: translateX(525px);
}
</style>
