import i18n from './i18n';

export default {
  email: {
    getMessage() {
      return 'validation.email';
    },
    condition(data) {
      return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(data);
    },
  },
  required: {
    getMessage() {
      return 'validation.required';
    },
    condition(data) {
      return data.length > 0;
    },
  },
  mask: {
    getMessage() {
      return 'validation.mask';
    },
  },
  upper: {
    getMessage() {
      return 'validation.uppercase';
    },
    condition(data, value) {
      return /[A-Z]/.test(data);
    },
  },
  lower: {
    getMessage() {
      return 'validation.lowercase';
    },
    condition(data, value) {
      return /[a-z]/.test(data);
    },
  },
  number: {
    getMessage() {
      return 'validation.number';
    },
    condition(data, value) {
      return /[0-9]/.test(data);
    },
  },
  'special-char': {
    getMessage() {
      return 'validation.special-char';
    },
    condition(data, value) {
      return /[!@#$%&]/.test(data);
    },
  },
  min: {
    getMessage() {
      return 'validation.min';
    },
    condition(data, value) {
      return data.length >= value;
    },
  },
};
